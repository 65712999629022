<template>
    <v-container fluid :class="[initialised ? `initialised` : '']" v-if="!loading">
        <div class="internal-wrap">
            <v-btn text class="zindex-2 mt-6" @click="goBack()" v-if="$vuetify.breakpoint.xsOnly">
                <v-icon>mdi-chevron-left</v-icon>
                Back
            </v-btn>
            <div class="user-avatar">
                <v-img
                    :loading="!user"
                    :src="assets.user.userCircle"
                    max-width="120"
                    max-height="120"
                    class="mx-auto"
                    contain
                    eager></v-img>
            </div>
            <div class="dialog-row">
                <v-container px-5 pb-6 class="align-center d-flex flex-column">
                    <div class="user-card pa-3">
                        <v-row class="d-flex text-center mx-auto">
                            <v-col cols="4" class="d-flex align-center justify-center">
                                <div>
                                    <p class="pa-0 ma-0">{{ userInfo.fullName }}</p>
                                    <small v-if="userInfo.premium">
                                        <v-icon color="success" class="mr-2">mdi-crown</v-icon>Premium membership
                                    </small>
                                </div>
                            </v-col>
                            <v-col class="my-auto" cols="4">
                                <p class="ma-0">Joined - {{ userInfo.joined }}</p>
                            </v-col>
                            <v-col class="my-auto" cols="4">
                                <div v-if="userInfo.premium">
                                    <p class="ma-0">
                                        Until -
                                        {{ new Date(this.history.at(-1).expiryDate).toDateString() }}
                                    </p>
                                    <v-btn v-show="$vuetify.breakpoint.smAndDown" color="success">
                                        <v-icon>mdi-crown</v-icon>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn v-show="$vuetify.breakpoint.mdAndUp" @click="payment()" color="success">
                                        <div>Unlock Full Membership</div>
                                        <v-icon class="ml-2">mdi-crown</v-icon>
                                    </v-btn>
                                    <v-btn v-show="$vuetify.breakpoint.smAndDown" @click="payment()" color="success">
                                        <v-icon>fa-unlock-alt</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="width-half">
                        <h4 class="py-5">Info</h4>
                        <v-row dense>
                            <v-col md="5" cols="12">
                                <v-text-field
                                    ref="firstName"
                                    v-model="userInfo.firstName"
                                    :disabled="loading || !editable"
                                    required
                                    label="First name"
                                    class="pt-0 my-5"
                                    placeholder="Enter your first name...">
                                </v-text-field>
                            </v-col>
                            <v-col cols="0" md="2"> </v-col>
                            <v-col md="5" cols="12">
                                <v-text-field
                                    ref="lastName"
                                    v-model="userInfo.lastName"
                                    :disabled="loading || !editable"
                                    required
                                    label="Last name"
                                    class="pt-0 my-5"
                                    placeholder="Enter your last name...">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col md="12" cols="12">
                                <v-text-field
                                    ref="email"
                                    v-model="userInfo.email"
                                    type="email"
                                    :disabled="loading || !editable"
                                    label="Email Address"
                                    required
                                    class="pt-0 my-5"
                                    placeholder="Enter your email...">
                                </v-text-field>
                            </v-col>
                            <v-col cols="0" md="1"> </v-col>
                            <!-- <v-col md="5" cols="12">
              <v-text-field
                ref="phoneNumber"
                v-model="userInfo.phone"
                type="phone"
                :disabled="loading || !editable"
                label="Phone Number"
                required
                class="pt-0 my-5  text-2xl"
                
                placeholder="Enter your phone number..."
              >
              </v-text-field>
            </v-col> -->
                        </v-row>
                    </div>
                    <div class="width-half">
                        <h4 class="my-5">Active Subscription</h4>
                        <template v-if="purchases && purchases.length">
                            <PaymentCard :subscriptions="purchases" @updateSubscription="getRedeemedPurchases" />
                        </template>
                        <template v-else>
                            <v-row class="d-flex text-center mx-auto">
                                <v-col cols="12 mx-auto">
                                    <h2 class="" justify-center align-center>Currently No Subscriptions To Display</h2>
                                </v-col>
                            </v-row>
                        </template>
                        <h4 class="my-5">Transactions</h4>
                        <template v-if="transactions && transactions.length">
                            <PaymentTransaction :transactions="transactions" />
                        </template>
                        <template v-else>
                            <v-row class="d-flex text-center mx-auto">
                                <v-col cols="12 mx-auto">
                                    <h6 class="" justify-center align-center>Currently No Payments To Display</h6>
                                </v-col>
                            </v-row>
                        </template>
                    </div>
                </v-container>
            </div>
            <!-- <div class="footer-row pa-5 mw-600 mx-auto">
          <p class="caption ma-0">&copy; 2022 Red Sparrow Books</p>
      </div> -->
        </div>
    </v-container>
</template>
<script>
import PaymentCard from "@/components/account/payment-card.vue";
import PaymentTransaction from "@/components/account/payment-transaction.vue";
import { mapState } from "vuex";
import _ from "lodash";

export default {
    components: { PaymentCard, PaymentTransaction },
    data() {
        return {
            history: null,
            contactData: null,
            loading: false,
            editable: false,
            userInfo: {},
            transactions: [],
        };
    },
    computed: {
        ...mapState(["assets", "user"]), //"purchases"
        background() {
            // if (this.$router?.path?.includes("auth") || this.$router?.path?.includes("account")) {
            //     return "bg-blue";
            // } else if (this.$router?.path?.includes("home")) {
            //     return "bg-white";
            // } else {
            //     return "bg-blue";
            // }
            return "";
        },
        user() {
            this.init();
            return this.$app.user;
        },
        currentSubscription() {
            // console.warn("Purchases (Current Subscription):", this.purchases);
            if (this.purchases) {
                let sortBycreated = _.cloneDeep(this.purchases || []);
                sortBycreated.sort((a, b) => a.created - b.created);
                return sortBycreated[this.purchases.length - 1];
            } else return [];
        },
    },
    methods: {
        async getPaymentMethod() {
            let paymentMethods = await this.$fluro.api
                .get("/payment/method/6417b2a950f0263514813d79", { params: { noCache: true } })
                .then(({ data }) => {
                    // console.warn("Get Payment Methods:", data);
                    return data;
                })
                .catch((err) => console.log(err));
        },
        async getRedeemedPurchases() {
            //Mongo params to pass into fluro
            const query = {
                _type: "purchase",
                status: "active",
                managedPurchaser: this.$app?.user?.persona,
            };

            const purchases = await this.$fluro.api
                .post("/content/_query", query, {
                    params: {
                        noCache: true,
                    },
                })
                .then(({ data }) => {
                    return data;
                })
                .catch((err) => console.log(err));
            this.history = purchases;

            this.purchases = purchases;
        },
        goBack() {
            this.$router.go(-1);
        },
        payment() {
            this.$router.push({ name: "payment" });
        },
        async init() {
            this.loading = true;

            //Get the latest user information
            const user = this.$app.user;
            // const user = this.user;
            // this.checkPremium(user);
            console.log("user", user);
            const contactId = user?.contacts?.[0];
            console.log("Continue");

            if (contactId) {
                this.contactData = await this.$fluro.api
                    .get("/content/contact/" + contactId)
                    .then(({ data }) => {
                        console.log(data);
                        return data;
                    })
                    .catch((err) => console.log(err));
            }

            //Get transaction data
            // this.history = await this.getRedeemedPurchases();
            await this.getRedeemedPurchases();
            // this.history = this.purchases;

            console.log(this.contactData);
            if (user) {
                this.userInfo = {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    fullName: user.name,
                    email: user.email,
                    phone: user.phoneNumber || "00000000",
                    premium: user.isPremium || false,
                    joined: new Date(user.created).toDateString(),
                };
            }

            this.loading = false;
        },
    },
    created() {
        this.initialised = window.sessionStorage.initialised || false;

        setTimeout(() => {
            this.initialised = true;
            window.sessionStorage.initialised = true;
        }, 3500);
    },
    async mounted() {
        //init run on user change
        // console.warn("Purchases (Account - index.vue):", this.purchases);
        // console.log("Current Subscription", this.currentSubscription);

        this.transactions = await this.$fluro.api.get(`/content/transaction/`).then(({ data }) => data);
        // console.warn("Get Transactions", this.transactions);
        //CURRENTLY when Transactions are created, No owner or persona is attached to the Transaction. Unable to get "own" transactions from Fluro.

        this.paymentMethods = await this.getPaymentMethod();

        await this.init();
    },
};
</script>
<style lang="scss"></style>
